exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-cvbuilder-js": () => import("./../../../src/pages/cvbuilder.js" /* webpackChunkName: "component---src-pages-cvbuilder-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investigation-js": () => import("./../../../src/pages/investigation.js" /* webpackChunkName: "component---src-pages-investigation-js" */),
  "component---src-pages-privacy-and-cookie-policy-js": () => import("./../../../src/pages/privacy-and-cookie-policy.js" /* webpackChunkName: "component---src-pages-privacy-and-cookie-policy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-theanoncvtool-js": () => import("./../../../src/pages/theanoncvtool.js" /* webpackChunkName: "component---src-pages-theanoncvtool-js" */)
}

